<template>
  <section class="inner-banner single-banner 111"
           :style="`background: url(${pageSmallTile.url}) center center `">
    <div class="inner-oly">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner-content">
              <h1>{{pageSmallTile.title}}</h1>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"
                    v-for="(item,index) in pageSmallTile.tis"
                    :key="index">
                  <a :href="item.url">{{item.name}}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ScrollSmall',
  props: {
    pageSmallTile: {
      type: Object,
      default () {
        return {
          title: '暂无',
          url: '@/assets/img/1.jpg',
          tis: []
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inner-banner{
  width: 100%;
  height: 360px;
}
</style>
