<template>
  <div>
    <footer class="footer-base">
      <div class="content">
        <div class="container">
          <div class="row">
            <div class=" col-xs-12 col-md-3 footer-left">
              <div class="pad-r">
                <span class="bold font16">商务咨询</span><br /><br />
                yunping.ou@sicred.cn<br />
                企业电话：0755-86573482<br />
              </div>
            </div>
            <div class=" col-xs-12 col-md-3 footer-left">
              <div class="pad-r pad-l">
                <span class="bold font16">销售咨询</span><br /><br />
                华东区域：王先生 13801944929<br />
                华南区域：周小姐 18823876826 <br />
                华中区域：刘先生 13316560667<br />
                华西区域：沈先生 13922838847<br />
              </div>
            </div>
            <div class=" col-xs-12 col-md-4 footer-left">
              <div class="pad-r pad-l">
                <span class="bold font16">公司地址</span><br /><br />
                深圳总部：<br />
                深圳市南山区粤海街道高新区社区科技南十二号路012号曙光大厦866<br />
                上海分公司： <br />上海市嘉定工业区叶城路912号J
              </div>
            </div>

            <div class=" col-xs-12 col-md-2 footer-left">
              <div class="pad-l">
                <span class="bold font16">关注我们</span><br /><br />
                <img
                src="http://img.sicred.cn/assets/img/banner/ewm.png"
                class="product-img"
              />
              </div>
            </div>
          </div>
        </div>
        <div class="copy-row">
          <div class="col-md-12">
            ©2022 至信微电子
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备2022010673号-1</a
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  background-color: #222222;
  color: #999;
  position: relative;
  z-index: 1;
}

footer a {
  color: #f1f1f1;
}

footer h4 {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  text-align: left;
  position: relative;
}

footer h4:after {
  position: absolute;
  content: "";
  height: 3px;
  background-color: #f59703;
  width: 80px;
  bottom: -15px;
  left: 0;
}

footer .container {
  padding: 35px 15px;
}

footer hr.space {
  height: 20px;
  margin-left: 0;
}

footer .footer-left {
  text-align: left;
  color: #f1f1f1;
  height: 200px;
  border-right: 1px dashed #fff;
}

@media (max-width: 768px) {
    footer .container {
        padding: 5px 15px 35px 15px;
    }
  footer .footer-left {
    padding-top: 20px;
    text-align: left;
    color: #f1f1f1;
    height: auto;
    border-right: none;
    padding-bottom: 20px;
    border-bottom: 1px dashed #fff;
}
}

footer .footer-left:last-child {
  border-right: none;
}

footer .footer-right {
  text-align: right;
}

footer .footer-center {
  text-align: left;
}

footer .footer-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

footer .footer-center {
  padding-right: 20px;
}

footer .footer-right li {
  list-style-type: square !important;
  text-align: left;
}

footer .copy-row {
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  color: rgba(255,255,255,1);
}

footer .footer-left hr {
  margin: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}



footer ul {
  padding: 20px 0 0 0;
}

footer ul li {
  margin-bottom: 5px;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-logo img {
  width: auto;
  height: 50px;
}
</style>
