<template>
    <div class="applicationForm">
        <!-- 产品申请表 -->
        <div class="header">
            <div class="flex-sb">
                <div class="title1">产品申请表</div>
                <div class="title2">Sample Request</div>
            </div>
            <img src="http://img.sicred.cn/assets/img/common/block-title-line.png" class="img" />
        </div>
        <div class="content">
            <el-form :model="form" :rules="rules" :inline="true" label-position="left" ref="form" label-width="100px"
                class="form">
                <el-form-item label="姓名" prop="userName">
                    <el-input class="w250" v-model="form.userName" placeholder="请输入您的真实姓名"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="company">
                    <el-input class="w680" v-model="form.company" placeholder="请输入您的公司名称"></el-input>
                </el-form-item>
                <el-form-item label="应用产品" prop="product">
                    <el-input class="w680" v-model="form.product" placeholder="请输入您的应用产品"></el-input>
                </el-form-item>
                <el-form-item label="收件人" prop="userAddress">
                    <el-input class="w250" v-model="form.userAddress" placeholder="请输入收件人的姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="phone">
                    <el-input class="w370" v-model="form.phone" maxlength="11" placeholder="请输入您的手机号码"></el-input>
                </el-form-item>
                <el-form-item label="收件地址" prop="address">
                    <el-input class="w680" v-model="form.address" placeholder="请输入您的样品收件地址"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input class="w370" v-model="form.email" placeholder="请输入您的电子邮箱"></el-input>
                </el-form-item>
                <el-form-item label="样品型号" prop="model">
                    <el-input class="w370" v-model="form.model" placeholder="请输入您的样品型号"></el-input>
                </el-form-item>
                <el-form-item label="样片数量" prop="num">
                    <el-input class="w250" type="number" v-model="form.num" placeholder="如：3"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="button" @click="postInfo()">提交</div>
                </el-form-item>
            </el-form>
        </div>
        <div class="tip">
            注：您可以直接联系我们的销售或代理商
        </div>

    </div>
</template>

<script>
import getDate from '../server/getDate'
export default {
    data() {
        return {
            form: {
                userName: '', // 姓名
                company: '', // 公司
                product: '', // 应用产品
                userAddress: '', // 收件人
                phone: '', // 联系方式
                address: '', // 收件地址
                email: '', // 邮箱
                model: '', // 样品型号
                num: '', // 样片数量
            },
            rules: {
                userName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    { validator: this.validatePhone, trigger: ['change'] },
                ]
            },
            options: [
                {
                    value: 0,
                    label: '样品1'
                }
            ],
        }
    },
    methods: {
        validatePhone(rule, value) {
            const regex = /^1[3-9]\d{9}$/;
            if (!value || !regex.test(value)) {
                return Promise.reject("请输入正确的手机号码");
            }
            return Promise.resolve();
        },
        async postInfo() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    await getDate.postInfo({ ...this.form }, (response) => {
                        if (response.code == 1) {
                            this.$message.success('你的信息已经提交成功,稍后客服会与您取得联系！');
                            this.$refs.form.resetFields()
                        }
                    }, (exception) => {
                        this.$message.error('信息提交失败,请联系客服！');
                        console.log(exception)
                        return;
                    })
                } else {
                    return false;
                }
            });


        }
    }
}
</script>

<style lang="less" scoped>
.applicationForm {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    padding: 30px;

    .header {
        margin-bottom: 40px;

        .title1 {
            font-weight: 500;
            font-size: 36px;
            color: #002B5C;
        }

        .title2 {
            font-weight: bold;
            font-size: 40px;
            color: #D8E7F4;
        }

        .img {
            width: 83px;
            height: 5px;
            margin-bottom: 10px;
            display: block;
        }
    }

    .button {
        width: 346px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        background: #002B5C;
        border-radius: 6px;
        cursor: pointer;
    }

    .tip {
        color: #999;
        font-size: 12px;
    }
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

::v-deep .el-form-item {
    display: block;

    .el-form-item__label {
        color: #002B5C;
    }

    .el-input {
        .el-input__inner {
            height: 36px;
        }
    }
}

.w250 {
    width: 250px;
}

.w680 {
    width: 680px;
}

.w370 {
    width: 370px;
}

@media (max-width: 768px) {
    .applicationForm {
        padding: 15px;
        .header {
            .title1 {
                font-size: 20px;
            }
            .title2 {
                font-size: 18px;
            }
        }
       
    }

    .w250 {
        width: 100%;
    }

    .w680 {
        width: 100%;
    }

    .w370 {
        width: 100%;
    }

    .applicationForm {
        .button {
            display: block;
            width: 320px;
        }
    }
}</style>