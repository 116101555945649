<template>
  <div class="crumbs-box">
    <div class="container">
      <a class="mar10" href="#/index">首页</a> <span class="mar10">></span>
      <span class="tips">{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Crumbs",
  props: {
    name: {
      type: String,
      default: "产品系列",
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.crumbs-box {
  padding: 20px 0;
  color: #9c9c9c;
  font-size: 14px;
}
.container{
  padding: 0;
}
.crumbs-box a{
  color: #9c9c9c;
}
.mar10 {
  margin-right: 10px;
}
.tips {
  color: #2c2c2c;
}
</style>
