<template>
    <header>
        <div id="sticker" class="header-area">
            <div class="container-fluid">
                <div class="main-nav">
                    <div class="row">
                        <div class="col-md-2 col-sm-12 logo-bg">
                            <div class="logo">
                                <a class="navbar-brand page-scroll sticky-logo" href="#/index" title="至信微电子">
                                    <img src="http://img.sicred.cn/assets/img/new_logo.png" alt="至信微电子" />
                                </a>
                            </div>
                            <button type="button" @click="showSelect" id="sidebarCollapse" class="navbar-toggle collapsed"
                                data-toggle="collapse" data-target="#menu">
                                <span class="icon-bar"></span> <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                        </div>
                        <nav id="sidebar" :class="{ active: active }">
                            <div id="dismiss" @click="showSelect">
                                <i class="fa fa-arrow-left"></i>
                            </div>
                            <div class="sidebar-header"></div>
                            <ul class="mnav">
                                <li class="mnav ">
                                    <a href="#/index">首页</a>
                                </li>
                                <li class="mnav">
                                    <a href="#/product">
                                        产品介绍
                                    </a>

                                </li>
                                <li class="mnav">
                                    <a href="#/application-solutions">
                                        应用领域
                                    </a>
                                </li>
                                <li class="mnav">
                                    <a href="#/about" title="关于我们">
                                        关于我们
                                    </a>
                                </li>
                                <li class="mnav">
                                    <a href="#/contact-us" title="工作机会">
                                        工作机会
                                    </a>
                                </li>
                            </ul>
                            <ul class="list-unstyled siderbar-box">
                                <li>
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="tel:18924679667" target="_blank">0755-28229236</a>
                                </li>
                                <li>
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:inquiry@sicred.cn" target="_blank">
                                        inquiry@sicred.cn
                                    </a>
                                </li>
                                <li></li>
                            </ul>
                        </nav>
                        <div class="col-sm-10 hidden-xs hidden-sm pc-menu">
                            <nav class="navbar navbar-default hidden-xs hidden-sm">
                                <div class="collapse navbar-collapse" id="navbar-example">
                                    <div class="main-menu">
                                        <ul class="nav navbar-nav navbar-right">
                                            <li :class="{ active: tabActive == 1 }">
                                                <a href="#/index">首页</a>
                                            </li>
                                            <li :class="{ active: tabActive == 2 }">
                                                <a href="#/product" title="产品介绍">产品介绍</a>
                                                <!-- <ul class="sub-menu">
                          <li>
                            <a
                              href="#/products/sic-diode-three"
                              title="SiC MOSFET 碳化硅场效应管系列"
                              >SiC MOSFET 碳化硅场效应管系列</a
                            >
                          </li>
                          <li>
                            <a
                              href="#/products/sic-diode-four"
                              title="SiC MODULE 碳化硅模块系列"
                              >SiC MODULE 碳化硅模块系列</a
                            >
                          </li>
                          <li>
                            <a
                              href="#/products/sic-diode-five"
                              title="SiC SBD  碳化硅肖特基二极管系列"
                              >SiC SBD 碳化硅肖特基二极管系列</a
                            >
                          </li>
                        </ul> -->
                                            </li>
                                            <li :class="{ active: tabActive == 3 }">
                                                <a href="#/application-solutions" title="产品应用">产品应用</a>
                                                <!-- <ul class="sub-menu">
                          <li>
                            <a
                              href="#/products/sic-diode-three"
                              title="SiC MOSFET 碳化硅场效应管系列"
                              >SiC MOSFET 碳化硅场效应管系列</a
                            >
                          </li>
                          <li>
                            <a
                              href="#/products/sic-diode-four"
                              title="SiC MODULE 碳化硅模块系列"
                              >SiC MODULE 碳化硅模块系列</a
                            >
                          </li>
                          <li>
                            <a
                              href="#/products/sic-diode-five"
                              title="SiC SBD  碳化硅肖特基二极管系列"
                              >SiC SBD 碳化硅肖特基二极管系列</a
                            >
                          </li>
                        </ul> -->
                                            </li>
                                            <li :class="{ active: tabActive == 4 }">
                                                <a href="#/about" title="关于我们">关于我们</a>
                                            </li>
                                            <li :class="{ active: tabActive == 5 }">
                                                <a href="#/contact-us" title="联系我们">工作机会</a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>

                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/nav-->
    </header>
</template>

<script>
export default {
    name: "Header",
    props: {
        msg: String,
        tabActive: Number
    },
    data() {
        return {
            active: false,
        };
    },
    methods: {
        showSelect() {
            this.active = !this.active;
        },
    },
};
</script>

<style scoped>
.header-area {
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
}


.main-nav {
    padding: 0 10%;
}

.main-nav .navbar-brand {
    padding: 10px 0;
}

.main-nav .navbar-brand img {
    width: 150px;
    height: auto;
}



.main-menu ul.navbar-nav li {
    float: left;
    position: relative;
    margin-right: 35px;
}

.main-menu ul.navbar-nav>li>a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #0F0F0F;
    font-size: 18px;
    padding: 30px 0;
    margin: 0 15px;
}

@media (max-width: 1920px) {
    .main-nav {
        padding: 0 2% 8px;
    }
}

@media (max-width: 1600px) {
    .main-nav {
        padding: 0 2% 8px;
    }

    .main-menu ul.navbar-nav li {
        float: left;
        position: relative;
        margin-right: 60px;
    }

    .main-menu ul.navbar-nav>li>a {
        margin: 0 10px;
    }
}

@media (max-width: 768px) {

    .main-nav .navbar-brand img {
        width: 110px;
        height: auto;
    }


    .main-nav {
        padding: 0 2% 20px;
    }

    .main-menu ul.navbar-nav li {
        float: left;
        position: relative;
        margin-right: 10px;
    }

    .main-menu ul.navbar-nav>li>a {
        margin: 0 10px;
    }
}

.main-menu ul.navbar-nav li a:hover {
    color: #0F0F0F;
    font-weight: bold;
}

.main-menu ul.navbar-nav>li.active>a::before,
.main-menu ul.navbar-nav>li>a::before {
    background: #4FBFE9;
    content: '';
    height: 5px;
    position: absolute;
    bottom: 0;
    width: 0;
    transition: 0.5s;
    left: 0;
}

.main-menu ul.navbar-nav>li.active>a::before,
.main-menu ul.navbar-nav>li:hover>a:before {
    width: 100%;
}

.main-menu ul.navbar-nav li.active a:focus {
    color: #00111a;
}

.main-menu ul.navbar-nav li.active a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #0F0F0F;
    font-weight: bold;
    position: relative;
    z-index: 9999999;
}

.navbar {
    border: medium none;
    margin-bottom: 0;
}

.navbar-default {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}

.main-menu ul.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    background: 0 0;
    color: #333;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    background-color: transparent;
    color: #fff;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background: 0 0;
}

.main-menu ul.nav li ul.sub-menu {
    background: #ebebeb;
    border-bottom: 3px solid #6aafe6;
    border-top: 3px solid #6aafe6;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 115%;
    transition: 0.3s;
    visibility: hidden;
    width: 260px;
    z-index: -99;
    border-radius: 0 0 5px 5px;
}

.postrel {
    position: relative;
}

.navbar.navbar-default {
    float: left;
    margin-left: 15%;
}

.main-menu ul.nav li ul.sub-menu li {
    border-bottom: 1px solid #ddd;
    padding: 0;
    position: relative;
    width: 100%;
}

.main-menu ul.nav li:hover ul.sub-menu {
    top: 100%;
    opacity: 1;
    z-index: 999;
    visibility: visible;
}

.main-menu ul.nav li ul.sub-menu li a {
    color: #444;
    display: block;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 15px;
}

.main-menu ul.nav li:hover ul.sub-menu li a:hover {
    color: #fff;
    background: #6aafe6;
}

.main-menu ul.nav li ul.sub-menu li:last-child {
    border: none;
}


.icon-bar {
    background: #000 !important;
}

.navbar-toggle .icon-bar:nth-of-type(2) {
    top: 1px;
}

.navbar-toggle .icon-bar:nth-of-type(3) {
    top: 2px;
}

.navbar-toggle .icon-bar {
    position: relative;
    transition: all 0.1s ease-in-out;
}

.navbar-toggle.active .icon-bar:nth-of-type(1) {
    top: 6px;
    transform: rotate(45deg);
}

.navbar-toggle.active .icon-bar:nth-of-type(2) {
    background-color: transparent !important;
}

.navbar-toggle.active .icon-bar:nth-of-type(3) {
    top: -6px;
    transform: rotate(-45deg);
}

.navbar-collapse.in {
    overflow-y: visible;
}

#sidebar {
    display: none;
    width: 260px;
    position: fixed;
    top: 0;
    left: -265px;
    height: 100vh;
    z-index: 999;
    background-color: #fff;
    color: #636363;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar .search-box {
    width: 220px;
    height: 35px;
    box-sizing: border-box;
    vertical-align: middle;
    background-color: #fff;
    color: #333;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
}

#sidebar .search-box input {
    padding: 8px 10px;
    width: 170px;
    height: 35px;
    border-right: 0;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    color: #444;
    display: block;
    float: left;
    font-size: 14px;
    font-style: italic;
}

#sidebar .search-box button.button {
    width: 50px;
    height: 35px;
    line-height: 35px;
    background: #6aafe6;
    border: none;
    color: #fff;
    display: inline-block;
    float: right;
    font-size: 17px;
    text-align: center;
}

#sidebar.active {
    left: 0;
}

@media only screen and (max-width: 991px) {
    .navbar-toggle {
        display: block;
    }

    #sidebar {
        display: block;
    }
}

#dismiss {
    width: 20px;
    height: 20px;
    line-height: 35px;
    text-align: center;
    font-size: 2em;
    color: #333;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


#sidebar .sidebar-header {
    padding: 60px 10px 10px 20px;
}

#sidebar .mlag {
    text-align: left;
    font-size: 1.5rem;
    padding: 2px 0 5px 15px;
}

#sidebar .mlag i {
    padding-right: 5px;
}

#sidebar ul li.mnav a {
    padding: 8px 15px;
    font-size: 1.1em;
    display: block;
    border-top: 1px solid #ececec;
}

#sidebar ul li.mnav a i {
    font-family: FontAwesome;
    color: #6aafe6;
    display: block;
    position: absolute;
    top: 20%;
    right: 20px;
    font-size: 1.8em;
    transition: all 0.3s;
}

#sidebar ul li.mnav a.collapsed i {
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

#sidebar ul li.active>a {
    border-top: 1px solid #6aafe6;
}

#sidebar a[data-toggle='collapse'] {
    position: relative;
}

#sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

#sidebar ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
}

#sidebar a.download {
    background: #fff;
}

#sidebar a.article,
#sidebar a.article:hover {
    color: #fff !important;
}

#sidebar ul li.sidebar-contact a {
    margin: 20px 30px 10px;
    border: 3px solid #6aafe6;
    font-size: 1em;
    text-align: center;
}

#sidebar .siderbar-box {
    padding: 20px;
}

#sidebar .siderbar-box li {
    height: 30px;
    line-height: 30px;
}
</style>
