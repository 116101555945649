import http from './http.js'
import api from './api.js'

export default {
    postInfo(data,response,exception){
      http.post(api.index.postInfo,data,(e)=>{
        response(e);
      },(e)=>{
        exception(e)
      })
    },
}

